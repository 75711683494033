/*
 * @Descripttion: fileNames
 * @Author: cjtang
 * @Date: 2022-02-03 11:32:19
 * @LastEditors: cjtang
 * @LastEditTime: 2022-02-10 13:11:33
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('./views/AboutUs/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('./views/News/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('./views/News/NewsDetail/index.vue'),
    meta: {
      requireAuth: true
    },
  },
]

export default createRouter({
  history: createWebHashHistory(),
  routes
})